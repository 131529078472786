import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

import { AspectRatioWrapper, AvatarSmall, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import { PriceBreakDownModal } from './ListingCardPricebreakdown';
import { FindConditionLabelFromValue } from '../FieldSelect/ConditionSelect';
import appSettings from '../../config/settings';
import { createResourceLocatorString } from '../../util/routes';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl, id, slug, classes, author, discount } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);

  const fee = price?.amount * 0.05;
  const priceIncludingFee = price?.amount + fee;

  // price.amount = priceIncludingFee;

  const priceIncFeeMoney = new Money(priceIncludingFee, price.currency);
  const feeFormatted = new Money(fee, price.currency);

  const { formattedPrice: formattedPriceWithFee, priceTitle: priceTitleFee } = priceData(
    priceIncFeeMoney,
    config.currency,
    intl
  );

  const { formattedPrice: formattedFee } = priceData(feeFormatted, config.currency, intl);

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  const isUturn = author.id.uuid === appSettings.isUturn;
  const isTheturn = author.id.uuid === appSettings.isTheTurn;
  const discountedPrice = price.amount * 0.5;

  const formattedDiscountPrice = formatMoney(intl, new Money(discountedPrice, price.currency));

  const markedUpPrice = price.amount * 1.3;
  const formattedMarkedUpPrice = formatMoney(intl, new Money(markedUpPrice, price.currency));

  const feeDiscounted = discountedPrice * 0.05;
  const priceIncludingFeeDiscounted = discountedPrice + feeDiscounted;

  const formattedDiscountFee = formatMoney(intl, new Money(feeDiscounted, price.currency));

  const formattedPriceWithDiscountedFee = formatMoney(
    intl,
    new Money(priceIncludingFeeDiscounted, price.currency)
  );

  return (
    <>
      <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
        <div className={css.price}>
          {(isUturn && discount > 0) || (isTheturn && discount > 0) ? (
            <div className={css.priceValue} title={priceTitle}>
              {formattedDiscountPrice}
              <small>
                <s> {formattedPrice} </s>
              </small>
            </div>
          ) : (
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
          )}

          {isBookable ? (
            <div className={css.perUnit}>
              <FormattedMessage
                id="ListingCard.perUnit"
                values={{ unitType: publicData?.unitType }}
              />
            </div>
          ) : null}
        </div>
      </NamedLink>
      <div className={css.priceValueIncFee}>
        <PriceBreakDownModal
          formattedPriceWithFee={isUturn ? formattedPriceWithDiscountedFee : formattedPriceWithFee}
          price={formattedPrice}
          fee={formattedFee}
          isUturn={isUturn}
          discountPrice={formattedDiscountPrice}
          discountFee={formattedDiscountFee}
        />
      </div>
    </>
  );
};

function IconHeartFilledIn(props) {
  return (
    <svg viewBox="0 0 1024 1024" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z" />
    </svg>
  );
}

function IconHeart(props) {
  return (
    <svg viewBox="0 0 1024 1024" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z" />
    </svg>
  );
}

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    currentUser,
    showWishlist = true,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const isFreeShipping = listing?.attributes?.publicData?.shippingPriceInSubunitsOneItem === 0;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const [isWishlisted, setIsWishlisted] = useState(
    currentUser?.attributes.profile.privateData.favorites?.includes(listing.id.uuid)
  );

  const [disabled, setDisabled] = useState(false);

  const handleWishlist = async () => {
    if (disabled) return;

    if (typeof history !== 'undefined' && typeof location !== 'undefined') {
      // Only allow signed-in users to save favorites
      if (!currentUser) {
        const state = {
          from: `${location.pathname}${location.search}${location.hash}`,
        };
        window.location =
          '/login?redirect=' +
          encodeURIComponent(`${location.pathname}${location.search}${location.hash}`);
      } else {
        setDisabled(true);
        setIsWishlisted(!isWishlisted);
        const currentUserFetchedRaw = await window.app.sdk.currentUser.show();
        const currentUserFetched = ensureUser(currentUserFetchedRaw.data.data);

        const {
          attributes: { profile },
        } = currentUserFetched;
        const { favorites = [] } = profile.privateData || {};

        let payload;
        if (!profile.privateData || !profile.privateData?.favorites) {
          payload = {
            privateData: {
              favorites: [listing.id.uuid],
            },
          };
        } else if (isWishlisted) {
          payload = {
            privateData: {
              favorites: favorites.filter(f => f !== listing.id.uuid),
            },
          };
        } else {
          const rebuild = favorites.filter(f => f !== listing.id.uuid);
          payload = {
            privateData: {
              favorites: [...rebuild, listing.id.uuid],
            },
          };
        }

        const update = await window.app.sdk.currentUser.updateProfile(payload);
        setDisabled(false);
      }
    }
  };

  const isUturnOrTheturn =
    author.id.uuid === appSettings.isUturn || author.id.uuid === appSettings.isTheTurn;

  const { createdAt } = currentListing.attributes;
  // check if the listing is older than 30 days
  const listingDate = new Date(createdAt);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - listingDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  let discount = 0;

  if (diffDays > 35) {
    discount = 0.5;
  }

  return (
    <>
      <div className={css.listingCardWrapper}>
        {showWishlist && (
          <div className={css.wishlistHeart} onClick={() => handleWishlist()}>
            {isWishlisted ? <IconHeartFilledIn /> : <IconHeart />}
          </div>
        )}
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          <AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />

            {isFreeShipping && (
              <div className={css.freeShippingLabel}>
                <img src="/static/icons/free-shipping-icon-new.png" />
              </div>
            )}

            {isUturnOrTheturn && discount > 0 && (
              <div className={css.discountBadge}>
                <span>50% OFF</span>
              </div>
            )}

            <AvatarSmall user={author} className={css.avatar} />

            {/* <div className={css.autorImage}>
          <img src={profileImage} alt={authorName} />
        </div> */}
          </AspectRatioWrapper>
          <div className={css.info}>
            <div className={css.priceWrapper}>
              <PriceMaybe
                price={price}
                publicData={publicData}
                config={config}
                intl={intl}
                id={id}
                slug={slug}
                classes={classes}
                author={author}
                discount={discount}
              />
            </div>
            <div className={css.mainInfo}>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>

              {publicData.size && <span className={css.sizeInfo}>Size: {publicData?.size}</span>}
              {!publicData.size && publicData.condition && (
                <span className={css.sizeInfo}>
                  {FindConditionLabelFromValue(publicData?.condition)}
                </span>
              )}
            </div>
          </div>
        </NamedLink>
      </div>
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
