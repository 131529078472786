export class MessageScanner {
  constructor() {
    // URL regex pattern
    this.urlPattern = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;

    // Common suspicious patterns
    this.suspiciousPatterns = {
      // Seller / account verification patterns
      sellerVerification: /\b(seller\s*verification|verify\s*seller)\b/i,
      accountVerification: /\b(verify|confirm|validate)\s*(your\s*)?(account|details|identity|information)\b/i,
      adminVerification: /\b(admin|moderator)\s*(verification|message|request)\b/i,

      // Urgent action patterns
      urgentAction: /\b(urgent|immediate|action\s*required|attention\s*needed|respond\s*now)\b/i,
      forcedAction: /\b(account\s*suspended|account\s*on\s*hold|immediate\s*suspension)\b/i,

      // Polite but suspicious requests (e.g., "kindly verify your account")
      suspiciousRequest: /\b(kindly|please)\s*(verify|confirm|update)\s*(your|account|password|login)\b/i,
      passwordReset: /\b(reset|recover|restore)\s*your\s*password\b/i,

      // UTurn specific patterns
      uturnClaim: /\b(i'?m?\s*(from|an?|the)|this\s*is)\s*(the|an?)?\s*uturn\s*(admin|staff|support|team|representative|moderator|employee)\b/i,
      uturnOfficial: /\b(official|authorized)\s*uturn\s*(admin|staff|support|representative|moderator|employee)\b/i,
      uturnMessage: /\b(message|notice)\s*from\s*uturn\s*(admin|staff|support|team)\b/i,

      // Disguised or obfuscated URLs: matches patterns like "google dot com" or "example dot co dot uk"
      // This pattern looks for sequences of domain-like parts separated by "dot" or a literal '.' with optional spaces.
      //   disguisedUrl: /\b[a-zA-Z0-9-]+(?:\s*(?:dot|\.)\s*[a-zA-Z]{2,6})(?:\s*(?:dot|\.)\s*[a-zA-Z]{2,6})?\b/i,
    };
  }

  scanMessage(message) {
    const results = {
      containsUrls: false,
      urls: [],
      suspiciousContent: [],
      hasWarnings: false,
      hasUTurnClaims: false,
    };

    // Check for URLs
    const urls = message.match(this.urlPattern) || [];
    if (urls.length > 0) {
      results.containsUrls = true;
      results.urls = urls;
      results.hasWarnings = true;
    }

    // Check for suspicious patterns
    for (const [key, pattern] of Object.entries(this.suspiciousPatterns)) {
      const match = message.match(pattern);

      if (match) {
        console.log('match', pattern, match);
        results.suspiciousContent.push({
          type: key,
          pattern: pattern.toString(),
          matchedText: match[0],
        });
        results.hasWarnings = true;

        // Flag UTurn-specific matches
        if (key.toLowerCase().includes('uturn')) {
          results.hasUTurnClaims = true;
        }
      }
    }

    return results;
  }

  // Add custom pattern to check
  addPattern(name, pattern) {
    if (pattern instanceof RegExp) {
      this.suspiciousPatterns[name] = pattern;
    } else if (typeof pattern === 'string') {
      this.suspiciousPatterns[name] = new RegExp(pattern, 'i');
    }
  }
}
