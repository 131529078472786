export const categories = [
  {
    label: 'Womens Clothing',
    subOptions: [
      {
        label: 'Dresses',
        subOptions: [
          { label: 'Mini Dress', id: 'womens_clothing>dress>mini_dress' },
          { label: 'Midi Dress', id: 'womens_clothing>dress>midi_dress' },
          { label: 'Maxi Dress', id: 'womens_clothing>dress>maxi_dress' },
          { label: 'Dress', id: 'womens_clothing>dress>dress' },
          { label: 'Knit Dress', id: 'womens_clothing>dress>knit_dress' },
        ],
        id: 'womens_clothing>dress',
      },
      {
        label: 'Tops & T-shirts',
        subOptions: [
          { label: 'Long Sleeve', id: 'womens_clothing>tops_tshirts>long_sleeve' },
          { label: 'Sleeveless', id: 'womens_clothing>tops_tshirts>sleeveless' },
          { label: 'Short Sleeve', id: 'womens_clothing>tops_tshirts>short_sleeve' },
          { label: 'Womens T-shirts', id: 'womens_clothing>tops_tshirts>womens_tshirts' },
        ],
        id: 'womens_clothing>tops_tshirts',
      },
      {
        label: 'Jumpsuits & Rompers',
        subOptions: [
          { label: 'Jumpsuits', id: 'womens_clothing>jumpsuits_rompers>jumpsuits' },
          { label: 'Playsuits', id: 'womens_clothing>jumpsuits_rompers>playsuits' },
        ],
        id: 'womens_clothing>jumpsuits_rompers',
      },
      {
        label: 'Sweaters',
        subOptions: [
          { label: 'Jumpers And Sweaters', id: 'womens_clothing>sweaters>jumpers_and_sweaters' },
          { label: 'Cardigans', id: 'womens_clothing>sweaters>cardigans' },
          { label: 'Woollen Knit', id: 'womens_clothing>sweaters>woollen_knit' },
          { label: 'Cashmere Knits', id: 'womens_clothing>sweaters>cashmere_knits' },
          { label: 'Mini Dress', id: 'womens_clothing>sweaters>mini_dress' },
          { label: 'Long Sleeve', id: 'womens_clothing>sweaters>long_sleeve' },
        ],
        id: 'womens_clothing>sweaters',
      },
      {
        label: 'Jackets, Blazers & Coats',
        subOptions: [
          { label: 'Jackets', id: 'womens_clothing>jackets_blazers_coats>jackets' },
          { label: 'Blazer', id: 'womens_clothing>jackets_blazers_coats>blazer' },
          { label: 'Leather Jacket', id: 'womens_clothing>jackets_blazers_coats>leather_jacket' },
          { label: 'Bomber', id: 'womens_clothing>jackets_blazers_coats>bomber' },
          {
            label: 'Fur And Shearling',
            id: 'womens_clothing>jackets_blazers_coats>fur_and_shearling',
          },
          { label: 'Trench', id: 'womens_clothing>jackets_blazers_coats>trench' },
          { label: 'Cape And Poncho', id: 'womens_clothing>jackets_blazers_coats>cape_and_poncho' },
          { label: 'Coats', id: 'womens_clothing>jackets_blazers_coats>coats' },
          { label: 'Suits & Sets', id: 'womens_clothing>jackets_blazers_coats>suits_sets' },
        ],
        id: 'womens_clothing>jackets_blazers_coats',
      },
      {
        label: 'Jeans',
        subOptions: [
          { label: 'Straight Leg Jean', id: 'womens_clothing>jeans>straight_leg_jean' },
          { label: 'Skinny Leg Jean', id: 'womens_clothing>jeans>skinny_leg_jean' },
          { label: 'Flared Jean', id: 'womens_clothing>jeans>flared_jean' },
          { label: 'Wide Leg Jean', id: 'womens_clothing>jeans>wide_leg_jean' },
          { label: 'Mom Jean', id: 'womens_clothing>jeans>mom_jean' },
          { label: 'Boyfriend Jean', id: 'womens_clothing>jeans>boyfriend_jean' },
          { label: 'Jeans', id: 'womens_clothing>jeans>jeans' },
        ],
        id: 'womens_clothing>jeans',
      },
      {
        label: 'Pants',
        subOptions: [
          { label: 'Wide Leg', id: 'womens_clothing>pants>wide_leg' },
          { label: 'Straight Leg', id: 'womens_clothing>pants>straight_leg' },
          { label: 'Skinny Leg', id: 'womens_clothing>pants>skinny_leg' },
          { label: 'Sweats And Joggers', id: 'womens_clothing>pants>sweats_and_joggers' },
          { label: 'Pants', id: 'womens_clothing>pants>pants' },
        ],
        id: 'womens_clothing>pants',
      },
      {
        label: 'Shorts',
        id: 'womens_clothing>shorts',
      },
      {
        label: 'Skirts',
        subOptions: [
          { label: 'Mini Skirt', id: 'womens_clothing>skirts>mini_skirt' },
          { label: 'Midi Skirt', id: 'womens_clothing>skirts>midi_skirt' },
          { label: 'Maxi Skirt', id: 'womens_clothing>skirts>maxi_skirt' },
          { label: 'Skirt', id: 'womens_clothing>skirts>skirt' },
          { label: 'Midi Dress', id: 'womens_clothing>skirts>midi_dress' },
        ],
        id: 'womens_clothing>skirts',
      },
      {
        label: 'Swimwear',
        subOptions: [
          { label: 'Swimwear', id: 'womens_clothing>swimwear>swimwear' },
          { label: 'Bikini Set', id: 'womens_clothing>swimwear>bikini_set' },
          { label: 'Cover up', id: 'womens_clothing>swimwear>cover_up' },
        ],
        id: 'womens_clothing>swimwear',
      },
      // {
      //   label: 'Maternity Clothes',
      //   subOptions: [
      //     { label: 'Maternity Tops', id: 'womens_clothing>maternity_clothes>maternity_tops' },
      //     { label: 'Maternity Dresses', id: 'womens_clothing>maternity_clothes>maternity_dresses' },
      //     { label: 'Maternity Skirts', id: 'womens_clothing>maternity_clothes>maternity_skirts' },
      //     { label: 'Maternity Pants', id: 'womens_clothing>maternity_clothes>maternity_pants' },
      //     { label: 'Maternity Shorts', id: 'womens_clothing>maternity_clothes>maternity_shorts' },
      //   ],
      //   id: 'womens_clothing>maternity_clothes',
      // },
      {
        label: 'Activewear',
        subOptions: [
          { label: 'Outerwear', id: 'womens_clothing>activewear>outerwear' },
          { label: 'Tracksuits', id: 'womens_clothing>activewear>tracksuits' },
          { label: 'Pants', id: 'womens_clothing>activewear>pants' },
          { label: 'Tops & T-shirts', id: 'womens_clothing>activewear>tops_tshirts' },
          { label: 'Hoodies & Sweatshirts', id: 'womens_clothing>activewear>hoodies_sweatshirts' },
          { label: 'Sports Accessories', id: 'womens_clothing>activewear>sports_accessories' },
          { label: 'Sports Bras', id: 'womens_clothing>activewear>sports_bras' },
          { label: 'Other Activewear', id: 'womens_clothing>activewear>other_activewear' },
        ],
        id: 'womens_clothing>activewear',
      },
    ],
    id: 'womens_clothing',
  },
  {
    label: 'Womens Shoes',
    subOptions: [
      {
        label: 'Sneakers',
        subOptions: [
          { label: 'Low Top Sneakers', id: 'womens_shoes>sneakers>low_top_sneakers' },
          { label: 'Lace Up Sneakers', id: 'womens_shoes>sneakers>lace_up_sneakers' },
          { label: 'High Top Sneakers', id: 'womens_shoes>sneakers>high_top_sneakers' },
        ],
        id: 'womens_shoes>sneakers',
      },
      {
        label: 'Boots',
        subOptions: [
          { label: 'Ankle', id: 'womens_shoes>boots>ankle' },
          { label: 'Knee High', id: 'womens_shoes>boots>knee_high' },
          { label: 'Chelsea', id: 'womens_shoes>boots>chelsea' },
          { label: 'Combat', id: 'womens_shoes>boots>combat' },
          { label: 'Thigh High Boots', id: 'womens_shoes>boots>thigh_high_boots' },
          { label: 'Boots', id: 'womens_shoes>boots>boots' },
        ],
        id: 'womens_shoes>boots',
      },
      {
        label: 'Flats',
        subOptions: [
          { label: 'Ballerina', id: 'womens_shoes>flats>ballerina' },
          { label: 'Slip On', id: 'womens_shoes>flats>slip_on' },
          { label: 'Loafers', id: 'womens_shoes>flats>loafers' },
          { label: 'Oxfords', id: 'womens_shoes>flats>oxfords' },
          { label: 'Espadrilles', id: 'womens_shoes>flats>espadrilles' },
        ],
        id: 'womens_shoes>flats',
      },
      {
        label: 'Heels/Pumps',
        subOptions: [
          { label: 'Open Toe', id: 'womens_shoes>heelspumps>open_toe' },
          { label: 'Pump', id: 'womens_shoes>heelspumps>pump' },
          { label: 'Wedge', id: 'womens_shoes>heelspumps>wedge' },
          { label: 'Mules', id: 'womens_shoes>heelspumps>mules' },
          { label: 'Stiletto', id: 'womens_shoes>heelspumps>stiletto' },
          { label: 'Platform', id: 'womens_shoes>heelspumps>platform' },
          { label: 'Heel', id: 'womens_shoes>heelspumps>heel' },
        ],
        id: 'womens_shoes>heelspumps',
      },
      {
        label: 'Sandals',
        subOptions: [
          { label: 'Slides', id: 'womens_shoes>sandals>slides' },
          { label: 'Slingback', id: 'womens_shoes>sandals>slingback' },
          { label: 'Thong', id: 'womens_shoes>sandals>thong' },
          { label: 'Lace Up', id: 'womens_shoes>sandals>lace_up' },
          { label: 'Sandals', id: 'womens_shoes>sandals>sandals' },
        ],
        id: 'womens_shoes>sandals',
      },
    ],
    id: 'womens_shoes',
  },
  {
    label: 'Accessories',
    subOptions: [
      {
        label: 'Wallets/Purses',
        subOptions: [
          { label: 'Wallets / Purses', id: 'womens_accessories>walletspurses>wallets_purses' },
          { label: 'Purses', id: 'womens_accessories>walletspurses>purses' },
        ],
        id: 'womens_accessories>walletspurses',
      },
      {
        label: 'Bucket Bag & Totes',
        subOptions: [
          { label: 'Bucket Bag', id: 'womens_accessories>bucket_bag_totes>bucket_bag' },
          { label: 'Shoulder', id: 'womens_accessories>bucket_bag_totes>shoulder' },
          { label: 'Crossbody', id: 'womens_accessories>bucket_bag_totes>crossbody' },
          { label: 'Totes', id: 'womens_accessories>bucket_bag_totes>totes' },
        ],
        id: 'womens_accessories>bucket_bag_totes',
      },
      {
        label: 'Clutches',
        subOptions: [
          { label: 'Clutch', id: 'womens_accessories>clutches>clutch' },
          { label: 'Wallets / Purses', id: 'womens_accessories>clutches>wallets_purses' },
          { label: 'Shoulder', id: 'womens_accessories>clutches>shoulder' },
          { label: 'Crossbody', id: 'womens_accessories>clutches>crossbody' },
        ],
        id: 'womens_accessories>clutches',
      },
      {
        label: 'Backpacks',
        subOptions: [{ label: 'Backpack', id: 'womens_accessories>backpacks>backpack' }],
        id: 'womens_accessories>backpacks',
      },
      {
        label: 'Shoulder Bags & Satchels',
        subOptions: [
          { label: 'Satchels', id: 'womens_accessories>shoulder_bags_satchels>satchels' },
          { label: 'Shoulder', id: 'womens_accessories>shoulder_bags_satchels>shoulder' },
        ],
        id: 'womens_accessories>shoulder_bags_satchels',
      },
      { label: 'Sunglasses', id: 'womens_accessories>sunglasses' },
      { label: 'Jewellery & Scarves', id: 'womens_accessories>jewellery_scarves' },
    ],
    id: 'womens_accessories',
  },
  {
    label: 'Men Clothing',
    id: 'men_clothing',
    subOptions: [
      {
        label: 'Jeans',
        id: 'men_clothing>jeans',
        subOptions: [
          { label: 'Ripped jeans', id: 'men_clothing>jeans>ripped_jeans' },
          { label: 'Skinny jeans', id: 'men_clothing>jeans>skinny_jeans' },
          { label: 'Slim fit jeans', id: 'men_clothing>jeans>slim_fit_jeans' },
          { label: 'Straight fit jeans', id: 'men_clothing>jeans>straight_fit_jeans' },
        ],
      },
      {
        label: 'Tops & T-shirts',
        id: 'men_clothing>tops_tshirts',
        subOptions: [
          { label: 'Shirts', id: 'men_clothing>tops_tshirts>shirts' },
          { label: 'T-shirts', id: 'men_clothing>tops_tshirts>tshirts' },
          { label: 'Tank tops', id: 'men_clothing>tops_tshirts>tank_tops' },
        ],
      },
      {
        label: 'Jackets',
        id: 'men_clothing>jackets',
        subOptions: [
          { label: 'Bomber jackets', id: 'men_clothing>jackets>bomber_jackets' },
          { label: 'Denim jackets', id: 'men_clothing>jackets>denim_jackets' },
          { label: 'Leather jackets', id: 'men_clothing>jackets>leather_jackets' },
          { label: 'Parkas', id: 'men_clothing>jackets>parkas' },
          { label: 'Puffer jackets', id: 'men_clothing>jackets>puffer_jackets' },
          { label: 'Trench coats', id: 'men_clothing>jackets>trench_coats' },
          { label: 'Jackets', id: 'men_clothing>jackets>jackets' },
        ],
      },
      {
        label: 'Suits, blazers & Vests',
        id: 'men_clothing>suits_blazers_vests',
        subOptions: [
          {
            label: 'Suit jackets & blazers',
            id: 'men_clothing>suits_blazers_vests>suit_jackets_blazers',
          },
          { label: 'Suit pants', id: 'men_clothing>suits_blazers_vests>suit_pants' },
          { label: 'Suit sets', id: 'men_clothing>suits_blazers_vests>suit_sets' },
          { label: 'Vests', id: 'men_clothing>suits_blazers_vests>vests' },
          { label: 'Wedding suits', id: 'men_clothing>suits_blazers_vests>wedding_suits' },
          { label: 'Coats', id: 'men_clothing>suits_blazers_vests>coats' },
        ],
      },
      {
        label: 'Sweaters & sweatshirts',
        id: 'men_clothing>sweaters_sweatshirts',
        subOptions: [
          { label: 'Cardigans', id: 'men_clothing>sweaters_sweatshirts>cardigans' },
          {
            label: 'Crew neck sweaters',
            id: 'men_clothing>sweaters_sweatshirts>crew_neck_sweaters',
          },
          { label: 'V-neck sweaters', id: 'men_clothing>sweaters_sweatshirts>v-neck_sweaters' },
          {
            label: 'Turtleneck sweaters',
            id: 'men_clothing>sweaters_sweatshirts>turtleneck_sweaters',
          },
          { label: 'Long sweaters', id: 'men_clothing>sweaters_sweatshirts>long_sweaters' },
          { label: 'Sweater vests', id: 'men_clothing>sweaters_sweatshirts>sweater_vests' },
          { label: 'Hoodies', id: 'men_clothing>sweaters_sweatshirts>hoodies' },
        ],
      },
      {
        label: 'Pants',
        id: 'men_clothing>pants',
        subOptions: [
          { label: 'Chinos', id: 'men_clothing>pants>chinos' },
          { label: 'Cropped pants', id: 'men_clothing>pants>cropped_pants' },
          { label: 'Skinny pants', id: 'men_clothing>pants>skinny_pants' },
          { label: 'Sweatpants', id: 'men_clothing>pants>sweatpants' },
          { label: 'Tailored pants', id: 'men_clothing>pants>tailored_pants' },
          { label: 'Wide-leg pants', id: 'men_clothing>pants>wide_leg_pants' },
        ],
      },
      {
        label: 'Shorts',
        id: 'men_clothing>shorts',
        subOptions: [
          { label: 'Cargo shorts', id: 'men_clothing>shorts>cargo_shorts' },
          { label: 'Chino shorts', id: 'men_clothing>shorts>chino_shorts' },
          { label: 'Denim shorts', id: 'men_clothing>shorts>denim_shorts' },
          { label: 'Other shorts', id: 'men_clothing>shorts>other_shorts' },
        ],
      },
      {
        label: 'Swimwear',
        id: 'men_clothing>swimwear',
        subOptions: [{ label: 'Swimwear', id: 'men_clothing>swimwear>swimwear' }],
      },
      {
        label: 'Activewear',
        id: 'men_clothing>activewear',
        subOptions: [{ label: 'Tracksuits', id: 'men_clothing>activewear>tracksuits' }],
      },
    ],
  },
  {
    label: 'Mens Shoes',
    id: 'men_shoes',
    subOptions: [
      {
        label: 'Boots',
        id: 'men_shoes>boots',
        subOptions: [
          { label: 'Chelsea & slip-on boots', id: 'men_shoes>boots>chelsea_slip_on_boots' },
          { label: 'Desert & lace-up boots', id: 'men_shoes>boots>desert_lace_up_boots' },
          { label: 'Long Boots', id: 'men_shoes>boots>long_boots' },
          { label: 'Short Boots', id: 'men_shoes>boots>short_boots' },
          { label: 'Work boots', id: 'men_shoes>boots>work_boots' },
        ],
      },
      {
        label: 'Casual Shoes',
        id: 'men_shoes>casual_shoes',
      },
      {
        label: 'Dress Shoes',
        id: 'men_shoes>dress_shoes',
      },
      {
        label: 'Sandals, Slides & Thongs',
        id: 'men_shoes>sandals_slides_thongs',
        subOptions: [
          { label: 'Flip-flops & slides', id: 'men_shoes>sandals_slides_thongs>flip_flops_slides' },
          { label: 'Sandals', id: 'men_shoes>sandals_slides_thongs>sandals' },
          { label: 'Slippers', id: 'men_shoes>sandals_slides_thongs>slippers' },
        ],
      },
      {
        label: 'Sports Shoes & Sneakers',
        id: 'men_shoes>sports_shoes_sneakers',
        subOptions: [
          { label: 'Low Tops', id: 'men_shoes>sports_shoes_sneakers>low_tops' },
          { label: 'Hi Tops', id: 'men_shoes>sports_shoes_sneakers>hi_tops' },
          { label: 'Lifestyle Sneakers', id: 'men_shoes>sports_shoes_sneakers>lifestyle_sneakers' },
          { label: 'Running Shoes', id: 'men_shoes>sports_shoes_sneakers>running_shoes' },
        ],
      },
    ],
  },
  {
    label: 'Accessories',
    id: 'men_accessories',
    subOptions: [
      {
        label: 'Bags & Backpacks',
        id: 'men_accessories>bags_backpacks',
        subOptions: [
          { label: 'Backpacks', id: 'men_accessories>bags_backpacks>backpacks' },
          { label: 'Briefcases', id: 'men_accessories>bags_backpacks>briefcases' },
          {
            label: 'Satchels & Shoulder Bags',
            id: 'men_accessories>bags_backpacks>satchels_shoulder_bags',
          },
          { label: 'Wallets', id: 'men_accessories>bags_backpacks>wallets' },
        ],
      },
      {
        label: 'Hats & Caps',
        id: 'men_accessories>hats_caps',
        subOptions: [
          { label: 'Balaclavas', id: 'men_accessories>hats_caps>balaclavas' },
          { label: 'Beanies', id: 'men_accessories>hats_caps>beanies' },
          { label: 'Caps', id: 'men_accessories>hats_caps>caps' },
          { label: 'Hats', id: 'men_accessories>hats_caps>hats' },
        ],
      },
      {
        label: 'Jewellery & Watches',
        id: 'men_accessories>jewellery_watches',
        subOptions: [
          { label: 'Watches', id: 'men_accessories>jewellery_watches>watches' },
          { label: 'Jewellery', id: 'men_accessories>jewellery_watches>jewellery' },
        ],
      },
      {
        label: 'Accessories',
        id: 'men_accessories>accessories',
        subOptions: [
          { label: 'Belts & Suspenders', id: 'men_accessories>accessories>belts_suspenders' },
          { label: 'Gloves', id: 'men_accessories>accessories>gloves' },
          { label: 'Scarves', id: 'men_accessories>accessories>scarves' },
          {
            label: 'Other Mens Accessories',
            id: 'men_accessories>accessories>other_accessories',
          },
        ],
      },
    ],
  },
  {
    label: 'Womens Bags & Accessories',
    id: 'womens_bags_accessories',
    subOptions: [
      {
        label: 'Clutches',
        id: 'womens_bags_accessories>clutches',
        subOptions: [{ label: 'Clutch', id: 'womens_bags_accessories>clutches>clutch' }],
      },
      {
        label: 'Bucket Bag & Totes',
        id: 'womens_bags_accessories>bucket_bag_totes',
        subOptions: [
          { label: 'Cross Body', id: 'womens_bags_accessories>bucket_bag_totes>crossbody' },
          { label: 'Shoulder', id: 'womens_bags_accessories>bucket_bag_totes>shoulder' },
        ],
      },
    ],
  },
];

export const megaMenuItems = [
  {
    title: 'Women',
    categories: [{ label: 'Clothing' }, { label: 'Shoes' }, { label: 'Accessories' }],
    column1: [
      { label: 'Dresses', link: '/search?category=womens_clothing>dress' },
      { label: 'Tops & T-shirts', link: '/search?category=womens_clothing>tops_tshirts' },
      {
        label: 'Jumpsuits & Rompers',
        link: '/search?category=womens_clothing>jumpsuits_rompers',
      },
      { label: 'Sweaters', link: '/search?category=womens_clothing>sweaters' },
      {
        label: 'Jackets, Blazers & Coats',
        link: '/search?category=womens_clothing>jackets_blazers_coats',
      },
      { label: 'Jeans', link: '/search?category=womens_clothing>jeans' },
      { label: 'Pants', link: '/search?category=womens_clothing>pants' },
      { label: 'Short', link: '/search?category=womens_clothing>shorts' },
      { label: 'Skirts', link: '/search?category=womens_clothing>skirts' },
      { label: 'Swimwear', link: '/search?category=womens_clothing>swimwear' },
      { label: 'Activewear', link: '/search?category=womens_clothing>activewear' },
      { label: 'See all', link: '/search?category=womens_clothing' },
    ],
    column2: [
      { label: 'Sneakers', link: '/search?category=womens_shoes>sneakers' },
      { label: 'Boots', link: '/search?category=womens_shoes>boots' },
      { label: 'Flats', link: '/search?category=womens_shoes>flats' },
      { label: 'Heels/Pumps', link: '/search?category=womens_shoes>heelspumps' },
      { label: 'Sandals', link: '/search?category=womens_shoes>sandals' },
      { label: 'See all', link: '/search?category=womens_shoes' },
    ],
    column3: [
      {
        label: 'Wallets/Purses',
        link: '/search?category=womens_accessories>walletspurses',
      },
      {
        label: 'Bucket Bag & Totes',
        link: '/search?category=womens_accessories>bucket_bag_totes',
      },
      { label: 'Clutches', link: '/search?category=womens_accessories>clutches' },
      { label: 'Backpacks', link: '/search?category=womens_accessories>backpacks' },
      {
        label: 'Shoulder Bags & Satchels',
        link: '/search?category=womens_accessories>shoulder_bags_satchels',
      },
      { label: 'Sunglasses', link: '/search?category=womens_accessories>sunglasses' },

      {
        label: 'Jewellery & Scarves',
        link: '/search?category=womens_accessories>jewellery_scarves',
      },
      { label: 'See all', link: '/search?category=womens_accessories' },
    ],
    featuredBrands: [
      {
        name: 'Ralph Lauren',
        logo: '/static/popular-brands/Logo_RalphLauren.jpg',
        link: '/search?brand=RALPH%20LAUREN&category=womens_clothing',
      },
      {
        name: 'Zimmermann',
        logo: '/static/popular-brands/Logo_Zimmermann.jpg',
        link: '/search?brand=ZIMMERMANN&category=womens_clothing',
      },
      {
        name: 'Bec + Bridge',
        logo: '/static/popular-brands/Logo_BecBridge.jpg',
        link: '/search?brand=BEC%20AND%20BRIDGE&category=womens_clothing',
      },
      {
        name: 'Scanlan Theodore',
        logo: '/static/popular-brands/Logo_ScanlanTheodore.jpg',
        link: '/search?brand=SCANLAN%20THEODORE&category=womens_clothing',
      },
      {
        name: 'Country Road',
        logo: '/static/popular-brands/Logo_CountryRoad.jpg',
        link: '/search?brand=COUNTRY%20ROAD&category=womens_clothing',
      },
      {
        name: 'Gorman',
        logo: '/static/popular-brands/Logo_Gorman.jpg',
        link: '/search?brand=GORMAN&category=womens_clothing',
      },
      {
        name: 'Oroton',
        logo: '/static/popular-brands/Logo_Oroton.jpg',
        link: '/search?brand=OROTON&category=womens_clothing',
      },
      {
        name: 'Camilla',
        logo: '/static/popular-brands/Logo_Camilla.jpg',
        link: '/search?brand=CAMILLA&category=womens_clothing',
      },
    ],
  },
  {
    title: 'Men',
    categories: [{ label: 'Clothing' }, { label: 'Shoes' }, { label: 'Accessories' }],
    column1: [
      { label: 'Jeans', link: '/search?category=men_clothing>jeans' },
      { label: 'Tops & T-shirts', link: '/search?category=men_clothing>tops_tshirts' },
      {
        label: 'Suits, Blazers & Vests',
        link: '/search?category=men_clothing>suits_blazers_vests',
      },
      {
        label: 'Jackets',
        link: '/search?category=men_clothing>jackets',
      },
      {
        label: 'Sweaters & Sweatshirts',
        link: '/search?category=men_clothing>sweaters_sweatshirts',
      },
      { label: 'Pants', link: '/search?category=men_clothing>pants' },
      { label: 'Shorts', link: '/search?category=men_clothing>shorts' },
      { label: 'Swimwear', link: '/search?category=men_clothing>swimwear' },
      { label: 'Activewear', link: '/search?category=men_clothing>activewear' },
      { label: 'See all', link: '/search?category=men_clothing' },
    ],
    column2: [
      { label: 'Boots', link: '/search?category=men_shoes>boots' },
      { label: 'Casual Shoes', link: '/search?category=men_shoes>casual_shoes' },
      { label: 'Dress Shoes', link: '/search?category=men_shoes>dress_shoes' },
      {
        label: 'Sandals, Slides & Thongs',
        link: '/search?category=men_shoes>sandals_slides_thongs',
      },
      {
        label: 'Sports Shoes & Sneakers',
        link: '/search?category=men_shoes>sports_shoes_sneakers',
      },
      { label: 'See all', link: '/search?category=men_shoes' },
    ],
    column3: [
      {
        label: 'Bags & Backpacks',
        link: '/search?category=men_accessories>bags_backpacks',
      },
      { label: 'Hats & Caps', link: '/search?category=men_accessories>hats_caps' },
      {
        label: 'Jewellery & Watches',
        link: '/search?category=men_accessories>jewellery_watches',
      },
      { label: 'Accessories', link: '/search?category=men_accessories>accessories' },
      { label: 'See all', link: '/search?category=men_accessories' },
    ],
    featuredBrands: [
      {
        name: 'Levis',
        logo: '',
        link: '/search?brand=LEVIS&category=men_clothing',
      },
      {
        name: 'Armani',
        logo: '',
        link: '/search?brand=ARMANI&category=men_clothing',
      },
      {
        name: 'Puma',
        logo: '',
        link: '/search?brand=PUMA&category=men_clothing',
      },
      {
        name: 'Adidas',
        logo: '',
        link: '/search?brand=ADIDAS&category=men_clothing',
      },
      {
        name: 'Ben Sherman',
        logo: '',
        link: '/search?brand=BEN%20SHERMAN&category=men_clothing',
      },
      {
        name: 'Oxford',
        logo: '',
        link: '/search?brand=OXFORD&category=men_clothing',
      },
      {
        name: 'Tommy Hilfiger',
        logo: '',
        link: '/search?brand=TOMMY%20HILFIGER&category=men_clothing',
      },
      {
        name: 'Birkenstock',
        logo: '',
        link: '/search?brand=BIRKENSTOCK&category=men_shoes',
      },
    ],
  },

  {
    title: 'Brands',
    isDropdown: true, // Indicate that it's a simple dropdown
  },
  {
    title: 'Stores',
    link: '/stores', // This item will redirect instead of showing a dropdown
  },
  {
    title: 'Loyalty',
    link: '/profile-loyalty', // This item will redirect instead of showing a dropdown
  },
  // Add more menu items as needed
];

export const findCategoryById = id => {
  const stack = [...categories];
  while (stack.length > 0) {
    const currentOption = stack.pop();

    const cleanedId = id.replace(/＞/g, '>');
    if (currentOption.id === cleanedId) {
      return currentOption;
    }
    if (currentOption.subOptions) {
      stack.push(...currentOption.subOptions);
    }
  }
  return null; // ID not found
};

//function that when given a category id returns all sub categories underneath
export const findSubCategoriesById = (categories, id) => {
  // Base case: if categories is empty or null, return null (ID not found)
  if (!categories || categories.length === 0) {
    return null;
  }

  // Traverse through each category in the current level
  for (const currentOption of categories) {
    // Clean the ID (assuming id.replace(/＞/g, '>') as before)
    const cleanedId = id.replace(/＞/g, '>');

    // Check if the current category's ID matches the cleaned ID
    if (currentOption.id === cleanedId) {
      return currentOption.subOptions; // Return subcategories if ID matches
    }

    // If the current category has suboptions, recursively search in them
    if (currentOption.subOptions) {
      const result = findSubCategoriesById(currentOption.subOptions, id);
      if (result) {
        return result; // If found in subcategories, return the result
      }
    }
  }

  // If ID is not found in the current level or any sublevels, return null
  return null;
};

// function to get the parent category of a given category id
export const findParentCategory = (categories, id) => {
  // Base case: if categories is empty or null, return null (ID not found)
  if (!categories || categories.length === 0) {
    return null;
  }

  // Traverse through each category in the current level
  for (const currentOption of categories) {
    // Clean the ID (assuming id.replace(/＞/g, '>') as before)
    const cleanedId = id.replace(/＞/g, '>');

    // Check if the current category's ID matches the cleaned ID
    if (currentOption.id === cleanedId) {
      return currentOption; // Return the category if ID matches
    }

    // If the current category has suboptions, recursively search in them
    if (currentOption.subOptions) {
      const result = findParentCategory(currentOption.subOptions, id);
      if (result) {
        return currentOption; // If found in subcategories, return the current category
      }
    }
  }

  // If ID is not found in the current level or any sublevels, return null
  return null;
};

// check if a category is 3 levels down by id
export const isSubCategory = id => {
  const cleanedId = id.replace(/＞/g, '>');
  const split = cleanedId.split('>');
  return split.length === 3 || split.length === 2;
};

// a function that gets the parent of a 3rd level category and retrieves all the subcategories of that parent
function getParentCategories(categories, id, parents = []) {
  for (const category of categories) {
    if (category.subOptions) {
      for (const subOption of category.subOptions) {
        if (subOption.id === id) {
          return [...parents, category];
        }
        const foundParents = getParentCategories(category.subOptions, id, [...parents, category]);
        if (foundParents.length) {
          return foundParents;
        }
      }
    }
  }
  return [];
}

export function getParentCategory(categories, id) {
  for (const category of categories) {
    if (category.subOptions) {
      for (const subOption of category.subOptions) {
        if (subOption.id === id) {
          return category;
        }
        const parentCategory = getParentCategory(category.subOptions, id);
        if (parentCategory) {
          return parentCategory;
        }
      }
    }
  }
  return null;
}

export function getRootCategory(categories, id) {
  const parentCategories = getParentCategories(categories, id);
  return parentCategories.length ? parentCategories[0] : null;
}
