import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import './MegaMenu.css'; // Import general CSS file for styling
import css from './MegaMenu2.module.css'; // Import CSS module for scoped styling

import { brands } from '../../../config/configBrands'; // Brands data
import { megaMenuItems } from '../../../config/configCategories'; // Menu items data
import { NamedLink } from '../../../components';

const MegaMenu = () => {
  const [activeCategory, setActiveCategory] = useState(null); // State to track active menu
  const [brandsVisible, setBrandsVisible] = useState(false); // State for showing the brands dropdown
  const [filteredBrands, setFilteredBrands] = useState(brands); // State for filtered brands
  const timeoutRef = useRef(null); // Ref to store the timeout ID

  const isBrowser = typeof window !== 'undefined'; // Check if window is defined

  const isMobile = () => isBrowser && window.innerWidth <= 768; // Determine if it's mobile based on screen width

  const handleCategoryClick = menuItem => {
    if (menuItem.link) {
      // If the item has a link, redirect to that page
      window.location.href = menuItem.link;
    } else if (menuItem.title === 'Brands') {
      // Toggle the brands dropdown
      setBrandsVisible(!brandsVisible);
      setActiveCategory(null); // Close other dropdowns
    } else if (isMobile()) {
      // Toggle the menu items when clicked in mobile mode
      if (activeCategory === menuItem.title) {
        setActiveCategory(null); // Close the menu
        document.body.classList.remove('menuOpen'); // Remove scroll lock
      } else {
        setActiveCategory(menuItem.title); // Open the menu
        document.body.classList.add('menuOpen'); // Add scroll lock
        setBrandsVisible(false); // Close brands dropdown if another menu is clicked
      }
    }
  };

  const handleMouseEnter = menuItem => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (!isMobile()) {
      if (menuItem.link) {
        setActiveCategory(null); // Don't open a dropdown for link items like "Stores"
      } else if (menuItem.title === 'Brands') {
        setBrandsVisible(true); // Show brands dropdown on hover
        setActiveCategory(null); // Close other dropdowns
      } else {
        setActiveCategory(menuItem.title); // Open dropdown for other categories
        setBrandsVisible(false); // Close brands dropdown if another menu is hovered
      }
    }
  };

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = setTimeout(() => {
      setActiveCategory(null); // Close mega menu
      setBrandsVisible(false); // Close brands dropdown
    }, 40); // Adjust the delay for smoother user experience
  };

  const handleMenuEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const filterBrands = event => {
    const value = event.target.value.toLowerCase();
    const filtered = brands.filter(brand => brand.toLowerCase().includes(value));
    setFilteredBrands(filtered);
  };

  useEffect(() => {
    // Clean up the timeout when the component unmounts
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const closeMenu = () => {
    setBrandsVisible(false); // Close brands dropdown
    setActiveCategory(null); // Close mega menu
    document.body.classList.remove('menuOpen'); // Remove scroll lock
  };

  const currentMenuItem = megaMenuItems.find(item => item.title === activeCategory);

  return (
    <div className={css.shopContainer}>
      {/* Shop Buttons */}
      <div className={css.shopButtons}>
        {megaMenuItems.map((menuItem, index) => (
          <div
            key={index}
            className={classNames(css.shopButton, {
              [css.active]: activeCategory === menuItem.title,
            })}
            onMouseEnter={() => handleMouseEnter(menuItem)} // Hover for desktop
            onMouseLeave={handleMouseLeave}
            onClick={() => handleCategoryClick(menuItem)} // Click to toggle submenu on mobile or redirect
          >
            <span>{menuItem.title}</span>
          </div>
        ))}
      </div>

      {/* Brands Dropdown */}
      {brandsVisible && (
        <div
          className={css.brandsDropdown}
          onMouseEnter={handleMenuEnter}
          onMouseLeave={handleMouseLeave}
        >
          <input type="text" placeholder="Search brands" onChange={filterBrands} />
          <ul className={css.brandsList}>
            {filteredBrands.map((brand, index) => (
              // <NamedLink
              //   name="SearchPageNew"
              //   to={{ search: `brand=${brand}` }}
              //   onClick={closeMenu}
              //   key={index}
              // >
              //   <li className={css.brandItem}>{brand}</li>
              // </NamedLink>
              <a href={`/search?brand=${brand}`} onClick={closeMenu} key={index}>
                <li className={css.brandItem}>{brand}</li>
              </a>
            ))}
          </ul>
        </div>
      )}

      {/* Mega Menu */}
      {activeCategory && !currentMenuItem?.link && (
        <div
          className={classNames(css.megaMenu, {
            [css.visible]: activeCategory,
          })}
          onMouseEnter={handleMenuEnter} // Keep menu open when hovering over content
          onMouseLeave={handleMouseLeave}
        >
          <div className={css.menuContent}>
            {/* First Column */}
            <div className={classNames(css.menuColumn, css.menuColumnScroll)}>
              <h4>{currentMenuItem.categories[0].label}</h4>
              <ul className={css.menuList}>
                {currentMenuItem.column1.map((item, index) => (
                  <li key={index}>
                    {/* <NamedLink
                      name="SearchPageNew"
                      to={{ search: `${item.link.replace('/search?', '')}` }}
                      onClick={closeMenu}
                    > */}
                    <a href={item.link} onClick={closeMenu}>
                      {item.label}
                      {/* </NamedLink> */}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Second Column */}
            <div className={css.menuColumn}>
              <h4>{currentMenuItem.categories[1].label}</h4>
              <ul className={css.menuList}>
                {currentMenuItem.column2.map((item, index) => (
                  <li key={index}>
                    {/* <NamedLink
                      name="SearchPage"
                      to={{ search: `${item.link.replace('/s?', '')}` }}
                      onClick={closeMenu}
                    >
                      {item.label}
                    </NamedLink> */}
                    <a href={item.link} onClick={closeMenu}>
                      {item.label}
                      {/* </NamedLink> */}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Third Column */}
            <div className={css.menuColumn}>
              <h4>{currentMenuItem.categories[2].label}</h4>
              <ul className={css.menuList}>
                {currentMenuItem.column3.map((item, index) => (
                  <li key={index}>
                    {/* <NamedLink
                      name="SearchPage"
                      to={{ search: `${item.link.replace('/s?', '')}` }}
                      onClick={closeMenu}
                    >
                      {item.label}
                    </NamedLink> */}
                    <a href={item.link} onClick={closeMenu}>
                      {item.label}
                      {/* </NamedLink> */}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Featured Brands Column */}
            <div className={css.featuredBrandsColumn}>
              <h4>Featured Brands</h4>
              <ul className={css.brandList}>
                {currentMenuItem.featuredBrands.map((brand, index) => (
                  <li key={index}>
                    {/* <NamedLink
                      name="SearchPage"
                      to={{ search: `${brand.link.replace('/s?', '')}` }}
                      onClick={closeMenu}
                    >
                      {brand.name}
                    </NamedLink> */}

                    <a href={brand.link} onClick={closeMenu}>
                      {brand.name}
                      {/* </NamedLink> */}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { MegaMenu };
